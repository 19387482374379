@import '~bootstrap/dist/css/bootstrap.min.css';

p {
  font-family: 'Georgia';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #003B4A;
  text-align: left;
  margin-left: 33px;
  margin-right: 33px;
}

h4 {
  font-family: 'Georgia';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #003B4A;
  margin-bottom: 18px;
  margin-left: 33px;
}

h3 {
  font-family: 'Georgia';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 39px;
  text-align: center;
  color: #003B4A;
}
.nav-link {
  width: 90px;
  height: 11px;
  font-family: 'Georgia';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #003B4A;
}

.send-email {
    width: 136.5px;
    height: 40.17px;
    left: 976.24px;
    top: 2593.62px;
    background:#7AA5BA;
    border-color: #7AA5BA;
}

.service-card {
  width: 352.8px;
  height: 440px;
  border: 3px solid #DDE9E3;
}

h3 {
  font-family: 'Georgia';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 39px;
  text-align: center;
  color: #003B4A;
}

.footer {
  background-image: url('./components/footer/footer.jpg');
  background-size:cover;
  background-repeat: no-repeat;
  margin: auto;
}